<template>
  <div v-if="questions">
    <base-table
        v-if="shownData.length"
        :columns="questionFields"
        :rows="shownData"
    />

    <table-pagination
        v-if="shownData.length"
        :totalPages="1"
        :countOnPage="countOnPage"
        :manual="true"
        :total="rowData.length"
        :count="countOnPage > shownData.length ? shownData.length : countOnPage"
        @changePage="changePage"
    />
    <div v-else class="no_results">
      <p>Нет результатов</p>
    </div>
  </div>
  <div v-else class="table-preloader">
    <preloader/>
  </div>
</template>

<script>
import {ref} from "@vue/reactivity";
import {useStore} from "vuex";
import {computed, onBeforeMount} from "@vue/runtime-core";
import Preloader from "@/components/Technical/Preloader.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import BaseTable from "@/components/Tables/BaseTable.vue";

export default {
  name: "question-table",
  components: {
    BaseTable,
    TablePagination,
    Preloader,
  },
  setup() {
    const store = useStore();

    const countOnPage = ref(15),
        page = ref(1),
        isPreloader = ref(false);
    const questions = computed(() => store.state.test.questions),
        rowData = computed(() => {
          return questions.value.map((quest) => {
            return {
              id: quest.id,
              sort: quest.sort,
              text: quest.text,
              group: quest.actual_group_id,
              type: quest.question_type_id,
              edit: quest.id,
            };
          });
        });

    const questionFields = [
      {
        field: "sort",
        headerName: "Сортировка",
      },
      {
        field: "text",
        headerName: "Текст",
      },
      {
        field: "group",
        headerName: "Группа",
      },
      {
        field: "type",
        headerName: "Тип",
      },
      {
        field: "edit",
        headerName: "",
        dest: "test/question",
        res: "edit",
      },
    ];

    const shownData = computed(() => {
      return rowData.value ? rowData.value.slice(pageDataStart.value, pageDataStart.value + countOnPage.value) : [];
    });

    const pageDataStart = ref(0);

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("test/getQuestions");
    });

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      questions,

      questionFields,
      rowData,
      shownData,

    };
  },
};
</script>

<style lang="scss" scoped>
.no_results {
  padding: 40px 10px;
}
</style>
